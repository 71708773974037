<template>
  <ion-page>
    <Header></Header>
    <ion-content :fullscreen="true">

      <section class="md-section" id="cart">
        <div class="container">
          <ion-grid>
            <ion-row>
              <ion-col size="12" size-lg="8">
                <div class="invisible-box">
                    <h5 style="padding-bottom: 1rem"><strong>Mi pedido</strong></h5>

                    <p v-if="!cart.length">Aún no hay productos en el carrito. <router-link :to="'/products'">Ver Catálogo</router-link></p>
                    <ion-grid>
                      <ion-row v-for="(item,i) in cart" :key="item.id" class="product-item">
                        <ion-col size="12" size-lg="6">
                          <ion-item lines="none">
                            <img :src="item.images[0]" :alt="item.name" class="thumbnail" slot="start"/>
                            <ion-label text-wrap>
                              <strong>{{ item.name }}</strong>
                              <h6 style="padding-top: 1rem" v-for="variation in item.variations" :key="variation.id">- {{ variation.variation }}: {{ (variation.options.find(x=>x.selected === true)).option }} </h6>
                            </ion-label>
                          </ion-item>
                        </ion-col>
                        <ion-col size="12" size-lg="6">
                          <ion-item lines="none" style="padding-top: 1rem">
                            <ion-buttons class="quantity-editor" slot="start">
                              <ion-button @click="lessQty(i)">
                                <ion-icon slot="icon-only" :icon="removeOutline"></ion-icon>
                              </ion-button>
                              <ion-button>
                                <p>{{ item.quantity }}</p>
                              </ion-button>
                              <ion-button @click="plusQty(i)">
                                <ion-icon slot="icon-only" :icon="addOutline"></ion-icon>
                              </ion-button>
                            </ion-buttons>
                            <ion-label>{{ (item.price * item.quantity).toFixed(2) }}€</ion-label>
                            <ion-buttons slot="end">
                              <ion-button @click="deleteCart(i)">
                                <ion-icon color="danger" :icon="trashOutline"></ion-icon>
                              </ion-button>
                            </ion-buttons>
                          </ion-item>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                </div>
              </ion-col>
              <ion-col size="12" size-lg="4">
                <div class="box bg-light2">
                  <h5 style="padding-bottom: 1rem"><strong>Resumen</strong></h5>
                  <ion-list lines="full" class="resumen">
                    <ion-item>
                      <ion-label>Subtotal</ion-label>
                      <ion-label>{{ subtotal.toFixed(2) }}€</ion-label>
                    </ion-item>
                    <ion-item>
                      <ion-label>Envío</ion-label>
                      <ion-label>{{ shipping.toFixed(2) }}€</ion-label>
                    </ion-item>
                    <ion-item lines="none">
                      <ion-label>Total</ion-label>
                      <ion-label text-wrap>
                        <h3><strong>{{ total.toFixed(2) }}€</strong></h3>
                        <h6>(Incluye {{ tax.toFixed(2) }}€ de I.V.A.)</h6>
                      </ion-label>
                    </ion-item>
                  </ion-list>
                  <ion-button color="secondary" shape="round" expand="full" style="margin-top: 1rem" :disabled="!cart.length" @click="$router.push({ path: `/checkout` })">
                    <ion-label>Compar</ion-label>
                  </ion-button>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </section>

      <Footer></Footer>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonPage, IonButton } from '@ionic/vue';
import { defineComponent } from 'vue';
import { trashOutline, addOutline, removeOutline } from 'ionicons/icons';

import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { mapActions, mapState } from 'vuex';

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonPage,
    Header,
    Footer,
    IonButton
  },
  data () {
      return {
          subtotal: 0,
          total: 0,
          tax: 0,
          shipping: 9,
      }
  },
  computed: {
    ...mapState(['cart'])
  },
  methods: {
    ...mapActions(['deleteCart', 'updateCart']),

    deleteProduct(pos){
      this.deleteCart(pos);
    },
    // Cantidad
    plusQty(i){
        this.cart[i].quantity = this.cart[i].quantity + 1;
        this.updateCart
        this.recalculate();
    },
    lessQty(i){
        if(this.cart[i].quantity > 1){
            this.cart[i].quantity = this.cart[i].quantity - 1;
            this.recalculate();
        }
    },

    recalculate(){
            let subtotal = 0;
            this.cart.forEach(product => {
                subtotal = subtotal + (product.price * product.quantity);
            });

            this.subtotal = subtotal;
            this.total = this.subtotal + this.shipping;
            this.tax = (this.total * 0.21);
    }
  },
  beforeMount(){
    this.recalculate()
  },
  setup() {
    return {
      trashOutline, addOutline, removeOutline
    }
  }
});
</script>

<style scoped>
  .thumbnail{
    width: 100px;
    height: 100px;
    object-fit: cover;
    object-position: center;
    margin-right: 1rem;
  }

  .product-item{
    vertical-align: middle;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.38);
    padding: 1rem 0;
  }

  .product-item ion-item{
    --padding-start: 0;
    --padding-end: 0
  }

  #cart{
      min-height: 75vh;
  }

  .resumen ion-item{  
    --padding-start: 0;
    --padding-end: 0
  }
  
</style>

import { IonContent, IonPage, IonButton } from '@ionic/vue';
import { defineComponent } from 'vue';
import { trashOutline, addOutline, removeOutline } from 'ionicons/icons';

import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { mapActions, mapState } from 'vuex';

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonPage,
    Header,
    Footer,
    IonButton
  },
  data () {
      return {
          subtotal: 0,
          total: 0,
          tax: 0,
          shipping: 9,
      }
  },
  computed: {
    ...mapState(['cart'])
  },
  methods: {
    ...mapActions(['deleteCart', 'updateCart']),

    deleteProduct(pos){
      this.deleteCart(pos);
    },
    // Cantidad
    plusQty(i){
        this.cart[i].quantity = this.cart[i].quantity + 1;
        this.updateCart
        this.recalculate();
    },
    lessQty(i){
        if(this.cart[i].quantity > 1){
            this.cart[i].quantity = this.cart[i].quantity - 1;
            this.recalculate();
        }
    },

    recalculate(){
            let subtotal = 0;
            this.cart.forEach(product => {
                subtotal = subtotal + (product.price * product.quantity);
            });

            this.subtotal = subtotal;
            this.total = this.subtotal + this.shipping;
            this.tax = (this.total * 0.21);
    }
  },
  beforeMount(){
    this.recalculate()
  },
  setup() {
    return {
      trashOutline, addOutline, removeOutline
    }
  }
});
